import { create } from 'zustand';

interface ModalTagState {
   status: boolean;
   children: React.ReactNode | null;
   className: string;
   position: string;
   title: string;
   mW: string | number;
   hideclose: boolean;
   color: string;
   setHandleStatusModalTag: (
      params: Partial<
         Omit<
            ModalTagState,
            'setHandleStatusModalTag' | 'resetHandleStatusModalTag'
         >
      >
   ) => void;
   resetHandleStatusModalTag: () => void;
}

const initialState = {
   status: false,
   children: null,
   className: '',
   position: 'center',
   title: '',
   mW: '',
   hideclose: false,
   color: '',
};

export const useModalTagStore = create<ModalTagState>()((set) => ({
   ...initialState,
   setHandleStatusModalTag: (params) =>
      set((state) => ({
         ...state,
         ...params,
         className: params.className ?? state.className,
         position: params.position ?? state.position,
         title: params.title ?? state.title,
         mW: params.mW ?? 520,
         hideclose: params.hideclose ?? false,
         color: params.color ?? state.color,
      })),
   resetHandleStatusModalTag: () => set(initialState),
}));
