'use client';

import App from '@/global/app';

type Props = {
   children: React.ReactNode;
};

const RootComponent = ({ children }: Props) => {
   return <App children={children} />;
};

export default RootComponent;
