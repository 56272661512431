import { useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { X } from '@phosphor-icons/react';

import { useModalTagStore } from '@/global/zustand/modalTagStore';

import styles from './TagModal.module.scss';

const TagModal = () => {
   const {
      status,
      children,
      className,
      title,
      hideclose,
      color,
      setHandleStatusModalTag,
   } = useModalTagStore();

   const router = useRouter();

   useEffect(() => {
      const handleRouteChangeComplete = () => {
         setHandleStatusModalTag({ status: false });
      };
      router?.events?.on('routeChangeComplete', handleRouteChangeComplete);
      return () => {
         router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
   }, [router, setHandleStatusModalTag]);

   if (!status) return null;

   const classNameBox = className
      ? styles.box + ' ' + styles[className]
      : styles.box;

   function handleClose() {
      setHandleStatusModalTag({ status: false });
   }

   return (
      <div className={styles.main}>
         <div
            className={classNameBox}
            style={{ borderTop: `8px solid ${color}` }}
            onClick={(e) => e.stopPropagation()}
         >
            <div className={styles.boxHeader}>
               {title && <p className={styles.title}>{title}</p>}
               {!hideclose && (
                  <button
                     aria-label="Close Modal"
                     type="button"
                     onClick={handleClose}
                     className="m-button m-button--round m-button--secondary"
                  >
                     <span>
                        <X weight="bold" />
                     </span>
                  </button>
               )}
            </div>
            {children}
         </div>
      </div>
   );
};

export default TagModal;
