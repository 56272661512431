'use client';

import { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';

import { X } from '@phosphor-icons/react';

import { useModalStore } from '@/global/zustand/modalStore';

import styles from './Modal.module.scss';

const Modal = () => {
   const router = useRouter();
   const {
      status,
      children,
      className,
      title,
      hideclose,
      outsideClick,
      setHandleStatusModal,
   } = useModalStore();

   const [isAnimating, setIsAnimating] = useState(false);

   useEffect(() => {
      const handleRouteChangeComplete = () => {
         setHandleStatusModal({ status: false });
      };
      router?.events?.on('routeChangeComplete', handleRouteChangeComplete);
      return () => {
         router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
   }, [router, setHandleStatusModal]);

   useEffect(() => {
      if (status) {
         setIsAnimating(true);
      } else {
         setIsAnimating(false);
      }
   }, [status]);

   const classNameBox = className
      ? styles.box + ' ' + styles[className]
      : styles.box;

   function handleClose() {
      setIsAnimating(false);
      setTimeout(() => {
         setHandleStatusModal({ status: false });
      }, 300);
   }

   return (
      <div
         className={`${styles.main} ${isAnimating ? styles.visible : styles.hidden}`}
      >
         <div
            className={`${classNameBox} ${status ? styles.visible : styles.hidden}`}
            onClick={(e) => e.stopPropagation()}
         >
            <div className={styles.boxHeader}>
               {title && <p className={styles.title}>{title}</p>}
               {!hideclose && (
                  <button
                     aria-label="Close Modal"
                     type="button"
                     onClick={handleClose}
                     className="m-button m-button--round m-button--secondary"
                  >
                     <span>
                        <X weight="bold" />
                     </span>
                  </button>
               )}
            </div>
            {children}
         </div>
      </div>
   );
};

export default Modal;
