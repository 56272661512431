'use client';

import Modal from './components/modal/Modal.component';
import TagModal from './components/tag-modal/TagModal';
import Toast from './components/toast/Toast';
import { useToastStore } from './zustand/toastStore';

type Props = {
   children: React.ReactNode;
};

export default function App({ children }: Props) {
   const { status } = useToastStore();

   return (
      <div className={'main'}>
         {children}
         <Modal />
         <TagModal />
         {status && <Toast />}
      </div>
   );
}
