import { create } from 'zustand';

interface ToastState {
   status: boolean;
   title: string;
   content: string;
   response: 'success' | string;
   setHandleStatusToast: (params: Partial<ToastState>) => void;
   resetHandleStatusToast: () => void;
}

const initialState = {
   status: false,
   title: '',
   content: '',
   response: 'success',
};

export const useToastStore = create<ToastState>()((set) => ({
   ...initialState,
   setHandleStatusToast: (params) => set((state) => ({ ...state, ...params })),
   resetHandleStatusToast: () => set(initialState),
}));
