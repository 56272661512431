'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/navigation';

import { CheckCircle, Warning, XCircle } from '@phosphor-icons/react';

import { useToastStore } from '@/global/zustand/toastStore';

import styles from './Toast.module.scss';

const Toast = () => {
   const router = useRouter();
   const { status, title, response, setHandleStatusToast } = useToastStore();
   const [checkColor, setCheckColor] = useState('#FFF');

   useEffect(() => {
      if (response === 'success') setCheckColor('#22C55E');
      else if (response === 'error') setCheckColor('#EF4444');
      else setCheckColor('#F97316');
   }, [response]);

   useEffect(() => {
      const handleRouteChangeComplete = () => {
         setHandleStatusToast({ status: false });
      };
      router?.events?.on('routeChangeComplete', handleRouteChangeComplete);
      return () => {
         router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
   }, [router, setHandleStatusToast]);

   useEffect(() => {
      if (status) {
         const timer = setTimeout(() => {
            setHandleStatusToast({ status: false });
         }, 2000);
         return () => clearTimeout(timer);
      }
   }, [status, setHandleStatusToast]);

   const renderIcon = useMemo(() => {
      if (response === 'success')
         return <CheckCircle weight="fill" color={'#000000'} />;
      else if (response === 'error')
         return <XCircle weight="fill" color={'#000000'} />;
      return <Warning weight="fill" color={'#000000'} />;
   }, [response]);

   const renderBgColor = useCallback(() => {
      if (response === 'success') return '#22C55E';
      else if (response === 'error') return '#F97316';
      return '#EF4444';
   }, [response]);

   return (
      <div className={styles.wrapper}>
         <div
            className={styles.toast}
            style={{ backgroundColor: renderBgColor() }}
            onClick={(e) => e.stopPropagation()}
         >
            {renderIcon}
            <div
               className={styles.content}
               style={{ backgroundColor: renderBgColor() }}
            >
               <p className={styles.title}>{title}</p>
            </div>
         </div>
      </div>
   );
};

export default Toast;
